<template>
  <div>

    <b-card
      no-body
      class="mb-0"
    >

      <table-top
        show-search
        @change:limit="limitChanged"
        @change:query="queryChanged"
        @click:add="showAddModal = true "
      />

      <rc-overlay :show="loading">
        <b-table
          ref="refUserListTable"
          class="position-relative"
          :items="teams"
          responsive
          :fields="tableColumns"
          primary-key="id"
          show-empty
          :empty-text="loading ? $t('shared.loading') : $t('shared.no-data') "
          :sort-by.sync="query.sortBy"
          :sort-desc.sync="query.isSortDirDesc"
        >

          <!-- Column: teamName -->
          <template #cell(teamName)="data">

            <div class="text-nowrap text-center">

              <b-link
                class="font-medium-5 d-block"
                :to="{ name: 'team-view', params: { id: data.item.id } }"
              >
                {{ data.item.teamName.name }}
              </b-link>

              <b-badge
                v-if="data.item.teamEndedAt"
                variant="light-secondary"
              >
                {{ $t('team.view.ended-work') }}
              </b-badge>
              <b-badge
                v-else-if="data.item.status"
                variant="light-success"
              >
                {{ $t('team.status-switcher.active') }}
              </b-badge>
              <b-badge
                v-else
                variant="light-danger"
              >
                {{ $t('team.status-switcher.inactive') }}
              </b-badge>
            </div>

          </template>

          <!-- Column: icons -->
          <template #cell(icons)="data">

            <feather-icon
              v-if="data.item.checklistComment"
              icon="MessageCircleIcon"
              size="16"
            /></template>

          <!-- Column: vehicle -->
          <template #cell(vehicle)="data">

            <div class="text-nowrap">
              {{ data.item.vehicle.sideNumber }}
            </div>

          </template>

          <!-- Column: paramedicUser -->
          <template #cell(paramedicUser)="data">

            <div class="">
              {{ data.item.paramedicUser.surname }}
              {{ data.item.paramedicUser.name }}
            </div>

          </template>

          <!-- Column: driverUser -->
          <template #cell(driverUser)="data">

            <div class="">
              {{ data.item.driverUser.surname }}
              {{ data.item.driverUser.name }}
            </div>

          </template>

          <!-- Column: teamStartedAt -->
          <template #cell(teamStartedAt)="data">

            <div class="">
              {{ transformDatetimeToHumanReadablePretty(data.item.teamStartedAt) }}
            </div>

          </template>

          <!-- Column: teamEndedAt -->
          <template #cell(teamEndedAt)="data">

            <div
              v-if="data.item.teamEndedAt"
              class=""
            >
              {{ transformDatetimeToHumanReadablePretty(data.item.teamEndedAt) }}
            </div>

          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">

            <div class="text-nowrap">
              <feather-icon
                icon="EyeIcon"
                class="cursor-pointer"
                size="16"
                @click="viewTeam(data.item.id)"
              />
            </div>

          </template>

        </b-table>
      </rc-overlay>

      <table-bottom
        :query="query"
        :total="total"
      />
    </b-card>
  </div>
</template>

<script>
import {
  BBadge,
  BCard, BLink, BTable,
} from 'bootstrap-vue'
import TableTop from '@/layouts/components/table/TableTop.vue'
import TableBottom from '@/layouts/components/table/TableBottom.vue'
import { transformDatetimeToHumanReadablePretty } from '@/helpers/helpers'
import RcOverlay from '@/layouts/components/rc/RcOverlay.vue'

export default {
  components: {
    TableBottom,
    TableTop,
    RcOverlay,

    BCard,
    BTable,
    BBadge,
    BLink,
  },
  data() {
    return {
      showAddModal: false,
      loading: false,

      total: 0,
      query: {
        sortBy: 'teamStartedAt',
        isSortDirDesc: true,
        query: null,
        limit: 10,
        page: 1,
      },

      tableColumns: [
        {
          label: this.$t('team.list.table-header.team-name'),
          key: 'teamName',
          // sortable: true,
        },
        {
          label: '',
          key: 'icons',
          value: null,
        },
        {
          label: this.$t('team.list.table-header.vehicle'),
          key: 'vehicle',
          sortable: false,
        },
        {
          label: this.$t('team.list.table-header.paramedic'),
          key: 'paramedicUser',
          sortable: false,
        },
        {
          label: this.$t('team.list.table-header.driver'),
          key: 'driverUser',
          sortable: false,
        },
        {
          label: this.$t('team.list.table-header.duty-from'),
          key: 'teamStartedAt',
          sortable: true,
        },
        {
          label: this.$t('team.list.table-header.duty-to'),
          key: 'teamEndedAt',
          sortable: true,
        },
        {
          label: this.$t('shared.actions'),
          key: 'actions',
          value: null,
          class: 'actions-column',
        },
      ],
    }
  },
  computed: {
    teams() {
      return this.$store.getters['team/getTeams']
    },
  },
  watch: {
    query: {
      handler() {
        this.refreshData()
      },
      deep: true,
    },
  },
  created() {
    this.refreshData()
  },
  methods: {
    transformDatetimeToHumanReadablePretty,
    refreshData() {
      this.loading = true
      const query = {
        q: this.query.query,
        sortField: this.query.sortBy,
        sortDir: this.query.isSortDirDesc ? 'desc' : 'asc',
        limit: this.query.limit,
        page: this.query.page,
      }

      Promise.all([this.$store.dispatch('team/fetchTeams', query)])
        .then(res => {
          if (this.query.page > 1 && res[0].data.items.length === 0) {
            this.query.page = 1
          }
          this.total = res[0].data.total
          this.loading = false
        })
    },
    deleteTeam(id) {
      return id
    },
    viewTeam(id) {
      this.$router.push(`/team/view/${id}`)
    },
    limitChanged(newLimit) {
      this.query.limit = newLimit
    },
    queryChanged(newQuery) {
      this.query.query = newQuery
    },
  },
}
</script>

<style lang="scss">

.actions-column {
  width: 1rem;
}
</style>
